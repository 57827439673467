import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Home from './components/home/Home'
import ClassRoom from './components/class-room/ClassRoom'
import Layout from './components/layout/Layout'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes> {/* Switch */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='/class-room' element={<ClassRoom />}></Route>
          </Route>
        </Routes> {/* Switch */}
      </Router >
    </div>
  );
}

export default App;
