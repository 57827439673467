import '../../App.css'
import './Home.css'

function Home() {
    return (
        <div className='home'>
            <div className='title'>
                <h2>Home</h2>
            </div>
        </div>
    )
}

export default Home