import './Footer.css';

function Footer() {
    return (
        <div className='footer sticky-top'>
            Designed and developed by <b>Matheus Sleutjes</b> - 2023
        </div>
    )
}

export default Footer