import '../../App.css'
import './ClassRoom.css'

function ClassRoom() {
    return (
        <div>
            <div className='title'>
                <h2>ClassRoom</h2>
            </div>
        </div>
    )
}
export default ClassRoom