import { Link } from "react-router-dom";
import './ButtomHeader.css'

function ButtomHeader(props) {
    return (
        <Link to={props.path} className="buttom">
            <b>{props.name}</b>
        </Link>
    )
}

export default ButtomHeader