import './Header.css';
import ButtomHeader from './buttom-header/ButtomHeader';

function Header() {
    return (
        <div className='header' >
            <ButtomHeader path='/' name='Home'/>
            <ButtomHeader path='./class-room' name='ClassRoom'/>
        </div>
    )
}

export default Header